import { useEffect } from "react";

const InstagramEmbed = () => {
  useEffect(() => {
    // Load the script asynchronously
    const script = document.createElement("script");
    script.id = "EmbedSocialHashtagScript";
    script.src = "https://embedsocial.com/cdn/ht.js";
    script.async = true; // Set the script to load asynchronously
    document.head.appendChild(script);

    const hideAnchor = () => {
      const esAnchor = document.querySelector<HTMLAnchorElement>(
        ".feed-powered-by-es"
      );
      if (esAnchor) {
        esAnchor.style.display = "none";
      }
    };

    // MutationObserver to observe changes in the DOM only when necessary
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          hideAnchor();
        }
      });
    });

    const observeDOM = () => {
      observer.observe(document.body, { childList: true, subtree: true });
    };

    // Lazy load and observe only when in viewport
    const lazyLoad = () => {
      const embedContainer = document.querySelector(".instagram-embed-container");

      if ("IntersectionObserver" in window) {
        const io = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              observeDOM();
              io.disconnect(); // Stop observing once loaded
            }
          });
        });
        if (embedContainer) io.observe(embedContainer);
      } else {
        // Fallback for browsers without IntersectionObserver
        observeDOM();
      }
    };

    lazyLoad();

    return () => {
      document.head.removeChild(script);
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="instagram-embed-container">
        <div
          className="embedsocial-hashtag"
          data-ref="5f2edccee22320b39fa0ae60a80b7b5bbe83fe3d"
        ></div>
      </div>
    </>
  );
};

export default InstagramEmbed;
